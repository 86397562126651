<template>
  <document-layout class="frontend">
    <document-search-result />
  </document-layout>
</template>

<script>
import DocumentSearchResult from '@/components/user/searchResult/DocumentSearchResult';

export default {
  name: 'SearchResult',
  components: {
    DocumentSearchResult,
  },
  mounted() {
    document.body.classList.add('frontend');
    document.body.classList.remove('backend');
  },
};
</script>

<style scoped></style>
