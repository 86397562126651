<template>
  <div v-if="documents">
    <div class="px-8 py-6 bg-white">
      <h1
        class="text-4xl font-bold leading-snug text-left responsive-container"
      >
        {{ $t('user.searchResult.title') }}
      </h1>
    </div>
    <div class="flex items-center responsive-container mx-8 mt-10 4xl:mx-auto">
      <user-document-filter
        ref="userDocumentFilterOverlay"
        class="mr-4"
        @filterResults="filterResults"
      />
      <asa-web-lex-search @setSearch="setSearchTermHeader" />

      <input
        id="isExactSearch"
        v-model="isExactSearch"
        type="checkbox"
        class="
          xl:ml-8
          ml-4
          min-w-5
          !h-5
          !bg-transparent
          border-2
          !border-secondary-600
          exact-search--checkbox
        "
      />
      <label for="isExactSearch" class="ml-4 text-secondary-600">
        {{ $t('search.exactSearch') }}
      </label>
    </div>

    <search-result-tabs
      class="mx-8 mt-10 responsive-container 4xl:mx-auto"
      :lang="currentLanguage"
      :documents="documents"
      :active-language="currentLanguage"
      @changeLanguageTab="setCurrentLanguage"
    />
    <div class="h-full mx-8 mb-16">
      <div class="py-12 bg-white shadow-md px-9 responsive-container">
        <div class="flex items-center justify-between w-2/3">
          <p class="text-base text-gray-400">
            {{ $t('user.searchResult.noResults.query', { term: searchTerm }) }}
          </p>
          <admin-document-list-pagination
            v-if="!hasNoResults"
            ref="searchResultPagination"
            class="flex lg:ml-auto frontend"
            :count="count"
            @goto="changePage"
          />
        </div>
        <user-selected-filter
          :selected-filters="selectedFilters"
          @deleteFilter="deleteFilter"
        />
        <div class="w-1/2 mt-4">
          <p v-if="hasNoResults" class="text-base">
            {{ $t('user.searchResult.noResults.information') }}
          </p>
          <document-result-list
            v-if="!hasNoResults"
            :results="results"
            :language="currentLanguage"
            :search-term="searchTerm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminDocumentListPagination from '@/components/admin/AdminDocumentListPagination';
import DocumentResultList from '@/components/user/searchResult/DocumentResultList';
import UserSelectedFilter from '@/components/user/searchResult/UserSelectedFilter';
import UserEventBus from '@/helpers/UserEventBus';
import { searchResults } from '@/services/Search.service';
import SearchResultTabs from '@/components/user/searchResult/SearchResultTabs.vue';
import { mapState } from 'vuex';
import { cleanFilters } from '@/helpers/filter';
import AsaWebLexSearch from '@/components/AsaWebLexSearch.vue';
import UserDocumentFilter from '@/components/user/searchResult/UserDocumentFilter.vue';

export default {
  name: 'DocumentSearchResult',
  components: {
    AdminDocumentListPagination,
    DocumentResultList,
    UserSelectedFilter,
    SearchResultTabs,
    AsaWebLexSearch,
    UserDocumentFilter,
  },
  data() {
    return {
      documents: {},
      searchTerm: '',
      currentPage: 1,
      currentLanguage: this.$i18n.locale,
      filters: {},
    };
  },
  computed: {
    ...mapState('Search', ['isExactSearch', 'selectedFilters']),
    hasNoResults() {
      return this.documents[this.currentLanguage]?.results.length <= 0;
    },
    results() {
      return this.documents[this.currentLanguage]?.results || [];
    },
    count() {
      return this.documents[this.currentLanguage]?.count;
    },
    isExactSearch: {
      get() {
        return this.$store.state.Search.isExactSearch;
      },
      set(newValue) {
        this.$store.commit('Search/setIsExactSearch', newValue);
        this.searchResults();
      },
    },
  },
  async mounted() {
    this.setInitialData();
    this.searchResults();
  },
  methods: {
    setInitialData() {
      if (Object.keys(this.selectedFilters).length === 0) {
        this.setSelectedFilters({
          state: [
            {
              id: 'is_in_order',
              name: this.$t('document.states.inOrder'),
              type: 'state',
            },
          ],
        });
      }

      if (this.$route.params.searchTerm) {
        this.setSearchTerm(this.$route.params.searchTerm);
      } else if (this.$route.query.term) {
        this.setSearchTerm(this.$route.query.term);
      }
      this.currentPage = 1;
    },
    async changePage(i) {
      const response = await searchResults(
        this.searchTerm,
        this.isExactSearch,
        {
          ...cleanFilters(this.selectedFilters),
          search_lang: this.currentLanguage,
        },
        i
      );
      this.documents[this.currentLanguage] = response.response;
    },
    deleteFilter(filter, filterKey) {
      UserEventBus.$emit('deleteFilter', filter, filterKey);
    },
    resetPagination() {
      if (this.$refs.searchResultPagination) {
        this.$refs.searchResultPagination.setCurrentPage(1);
      }
    },
    setFilters() {
      this.resetPagination();
      this.currentPage = 1;
    },
    setSelectedFilters(selectedFilters) {
      this.$store.commit('Search/setSelectedFilters', selectedFilters);
    },
    setSearchTerm(searchTerm) {
      this.resetPagination();
      this.currentPage = 1;
      this.searchTerm = searchTerm;
    },
    async searchResults() {
      const languages = ['de', 'fr', 'it', 'en'];
      const promises = [];
      languages.forEach((lang) => {
        promises.push(
          searchResults(
            this.searchTerm,
            this.isExactSearch,
            { ...cleanFilters(this.selectedFilters), search_lang: lang },
            this.currentPage
          )
        );
      });
      const responses = await Promise.allSettled(promises);
      responses.forEach((response, index) => {
        this.$set(this.documents, languages[index], response.value.response);
      });
      this.setSearchTermInUrl();
      this.isSearched = true;
    },
    setCurrentLanguage(lang) {
      this.currentPage = 1;
      this.resetPagination();
      this.currentLanguage = lang;
    },
    setSearchTermInUrl() {
      if (this.$route.query.searchTerm !== this.searchTerm) {
        window.history.pushState(
          {},
          null,
          `${this.$route.path}?term=${this.searchTerm}`
        );
      }
    },
    filterResults(filters, selectedFilters) {
      this.filters = filters;
      this.selectedFilters = selectedFilters;
      this.searchResults();
    },
    setSearchTermHeader(term) {
      this.searchTerm = term;
      if (this.isDefaultFilterNeeded()) {
        UserEventBus.$emit('setDefaultFilter');
      } else {
        this.setSearchTermInUrl();
        this.searchResults();
      }
    },

    isDefaultFilterNeeded() {
      // If no filters are used, we only search and then the default filter needs to be added.
      if (this.isObjectEmpty(this.filters)) {
        // If searched or just hit enter with no search term, add default filter.
        if (this.searchTerm || !this.searchTerm) {
          return true;
        }
      }
      return false;
    },
    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
  },
};
</script>

<style scoped lang="postcss">
.exact-search--checkbox:checked {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.20692 0.293031C9.39439 0.480558 9.49971 0.734866 9.49971 1.00003C9.49971 1.26519 9.39439 1.5195 9.20692 1.70703L4.20692 6.70703C4.01939 6.8945 3.76508 6.99982 3.49992 6.99982C3.23475 6.99982 2.98045 6.8945 2.79292 6.70703L0.792919 4.70703C0.610761 4.51843 0.509966 4.26583 0.512245 4.00363C0.514523 3.74143 0.619692 3.49062 0.8051 3.30521C0.990508 3.1198 1.24132 3.01463 1.50352 3.01236C1.76571 3.01008 2.01832 3.11087 2.20692 3.29303L3.49992 4.58603L7.79292 0.293031C7.98045 0.10556 8.23475 0.000244141 8.49992 0.000244141C8.76508 0.000244141 9.01939 0.10556 9.20692 0.293031Z' fill='%23079191'/%3E%3C/svg%3E%0A");
}
</style>
