<template>
  <div>
    <div
      v-for="document in results"
      :key="`document--${document.id}`"
      class="mt-8 text-secondary-600"
    >
      <router-link class="document--link" :to="getDocumentRoute(document)">
        {{ document.title }} {{ isFileOnlyDocument(document) ? '[PDF]' : '' }}
      </router-link>
      <div class="flex items-center">
        <p class="text-lg text-gray-800">
          <search-document-state :state="document.state" /> |
        </p>
        <document-information :document="document" />
      </div>
      <document-article-list
        :document="document"
        :language="language"
        :search-term="searchTerm"
      />
    </div>
  </div>
</template>

<script>
import DocumentArticleList from '@/components/user/searchResult/DocumentArticleList';
import SearchDocumentState from '@/components/SearchDocumentState';
import DocumentInformation from '@/components/user/searchResult/DocumentInformation';

export default {
  name: 'DocumentResultList',
  components: {
    DocumentArticleList,
    SearchDocumentState,
    DocumentInformation,
  },
  props: {
    results: {
      type: Array,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
  },
  methods: {
    isFileOnlyDocument(document) {
      return document.importType === 'file_only';
    },
    getDocumentRoute(document) {
      const route = {
        name: 'documentDetail',
        params: { id: document.id },
        query: { lang: this.language, q: this.searchTerm },
      };

      return route;
    },
  },
};
</script>
