<template>
  <div>
    <div v-for="article in articles" :key="`article--${article.id}`">
      <router-link
        :to="getArticleRoute(article)"
        class="text-lg no-underline mt-[0.125rem]"
        :class="
          article.score == highestScore
            ? '!text-secondary-700 bg-[#E5F5F6]'
            : ''
        "
      >
        {{ stripTags(article.text) }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentArticleList',
  props: {
    document: {
      type: Object,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
  },
  computed: {
    articles() {
      let blocks = this.document.blocks;
      if (!blocks) {
        return [];
      }
      return blocks.sort((a, b) => {
        let artA = a.articleNumber;
        let artB = b.articleNumber;
        // articleNumbers can be like NumberLetter e.g. 38a
        // We first order by number (e.g. 12, 14, 38...) and then by
        // letter (40a, 40b, 40c...)
        let sortIndex = artA.match(/\d+/)[0] - artB.match(/\d+/)[0];
        if (sortIndex === 0) {
          return artA.match(/[a-z]/)[0].localeCompare(artA.match(/[a-z]/)[0]);
        }
        return sortIndex;
      });
    },
    highestScore() {
      return Math.max(...this.document.blocks.map((a) => a.score));
    },
  },
  methods: {
    getArticleRoute(article) {
      const route = {
        name: 'documentDetail',
        params: { id: this.document.id, blockId: article.id },
        query: { lang: this.language, q: this.searchTerm },
      };

      return route;
    },
    stripTags(text) {
      return text.replace(/<\/?[^>]+>/gi, '');
    },
  },
};
</script>

<style scoped></style>
