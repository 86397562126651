<template>
  <div>
    <div class="flex search">
      <div
        ref="search"
        class="relative w-[320px]"
        :class="{ open: isSearchLogShown }"
      >
        <input
          v-model="term"
          type="text"
          :placeholder="$t('search.placeholder')"
          class="w-full !border-secondary-600 rounded-r-none text-gray-800"
          @keyup.enter="getResults"
          @search="getResults"
          @focus="showSearchLog"
        />
        <ul v-show="isSearchLogShown" class="search-results">
          <div class="flex justify-between px-5">
            <p class="my-2">{{ $t('search.searchLog') }}</p>
            <button @click="deleteSearchLogClick">
              <svg-icon name="close" />
            </button>
          </div>

          <li
            v-for="logEntry in searchLog"
            :key="logEntry.id"
            @click="search(logEntry)"
          >
            <div class="flex justify-between">
              {{ logEntry.term }}
              <button @click.stop="deleteSearchLogEntryClick(logEntry)">
                <svg-icon name="close" />
              </button>
            </div>
          </li>
        </ul>
      </div>

      <button class="search--button btn--icon-left btn" @click="getResults">
        <svg-icon name="search-1" class="!mr-0" />
      </button>
    </div>
  </div>
</template>

<script>
import {
  getSearchLog,
  deleteSearchLog,
  deleteSearchLogEntry,
} from '@/services/Search.service.js';
import { searchResults } from '@/services/Search.service';

export default {
  components: {
    SvgIcon: () => import('@/components/SvgIcon'),
  },
  data() {
    return {
      term: '',
      results: [],
      noResults: false,
      isSearchLogShown: false,
      searchLog: [],
    };
  },
  watch: {
    term: function (newTerm) {
      if (newTerm === '') {
        this.results = [];
        this.noResults = false;
      }
    },
  },
  watch: {
    '$i18n.locale'() {
      this.$store.dispatch('Search/loadAvailableFilters');
    },
  },
  async mounted() {
    document.addEventListener('click', this.handleDocumentClick);

    this.searchLog = await getSearchLog();
    this.$store.dispatch('Search/loadAvailableFilters');
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleDocumentClick);
  },
  methods: {
    async getResults() {
      this.noResults = false;
      let response = await searchResults(this.term, false, {}, 1);
      if (response.directSearch) {
        let params = { id: response.directSearch.documentId };
        if (response.directSearch.blockId) {
          params['blockId'] = response.directSearch.blockId;
        }
        await this.$router.push({
          name: 'documentDetail',
          params: params,
        });
        return;
      } else {
        this.$emit('setSearch', this.term);
      }
      this.results = response.documents;
      this.searchLog = await getSearchLog();
      if (!this.results.length) {
        this.noResults = true;
      }
      this.searchLog = await getSearchLog();
    },
    showSearchLog() {
      if (this.searchLog.length > 0) {
        this.isSearchLogShown = true;
      }
    },
    hideSearchLog() {
      this.isSearchLogShown = false;
    },
    search(logEntry) {
      this.term = logEntry.term;
      this.getResults();
      this.hideSearchLog();
    },
    handleDocumentClick(e) {
      // Return if the click was inside the search div
      if (this.$refs.search.contains(e.target)) {
        return;
      }
      this.hideSearchLog();
    },
    async deleteSearchLogClick() {
      await deleteSearchLog();
      this.hideSearchLog();
      this.searchLog = await getSearchLog();
    },
    async deleteSearchLogEntryClick(logEntry) {
      await deleteSearchLogEntry(logEntry.id);
      this.searchLog = await getSearchLog();
    },
  },
};
</script>

<style lang="postcss" scoped>
.search-results {
  @apply absolute z-50;
  @apply w-full;
  @apply overflow-auto;
  @apply border rounded-b-lg border-t-0;
  @apply bg-white;

  & li {
    @apply px-5 py-2 text-left;
    @apply hover:bg-gray-200 cursor-pointer;
  }
}

.frontend .search-results {
  @apply border-secondary-700;
  @apply text-secondary-600;
}

.backend .search-results {
  @apply border-blue-700;
  @apply text-blue-600;
}

.frontend .open {
  & input {
    @apply rounded-b-none border-b !border-b-green-800;
  }
}

.backend .open {
  & input {
    @apply rounded-b-none border-b !border-b-blue-800;
  }
}

.search--button {
  @apply text-white bg-secondary-800 rounded-l-none border-secondary-600 border-l-0;
  @apply shadow;
}
</style>
